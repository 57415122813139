import request from '../utils/request'

const apis = {
    UnitExplain: '/api/NewLesson/GetUnitExplainById',
    RemoveApx: '/api/NewLesson/RemoveApx',
    GetUnitApxList: '/api/NewLesson/GetUnitApxList',
    DelReUnitApx: '/api/NewLesson/DelReUnitApx',
    AddUnitApx: '/api/NewLesson/AddUnitApx',
    UpdateUnit: 'api/NewLesson/UpdateUnit',
    SectionInfo: '/api/NewLesson/GetSectionById',
    UpdateMySection: '/api/NewLesson/UpdateMySection',
    CreateMyAction: '/api/NewLesson/CreateMyAction',
    DelMyAction: '/api/NewLesson/DelMyAction',
    DelReSectionApx: '/api/NewLesson/DelReSectionApx',
    DelReLessonApx: '/api/NewLesson/DelReLessonApx',
    UpdataActionInfo: '/api/NewLesson/UpdataActionInfo',
    ActionSort: '/api/NewLesson/ActionSort',
    AddLessonApx: '/api/NewLesson/AddActionApx',
    AddSectionApx: '/api/NewLesson/AddSectionApx_new',
    MissingSectionApxList: '/api/NewLesson/MissingSectionApxList',
    MissingActionList: '/api/NewLesson/MissingActionList',
    DelAliYunFile: '/api/NewLesson/DelAliYunFile',
    ReAction: '/api/NewLesson/ReAction',
    GetBsIdeas: '/api/NewLesson/GetBsIdeas',
    SetUnitSelect: '/api/MyLesson/SetUnitSelect',
}

/**
 * 获取巴蜀思路
 * @param {sectionId:51} parameter 
 */
 export function getBsIdeas(parameter) {
    return request({
        url: apis.GetBsIdeas,
        method: 'post',
        data: parameter
    })
}

/**
 * 恢复删除模块
 * @param {actionId:8} parameter 
 */
 export function reAction(parameter) {
    return request({
        url: apis.ReAction,
        method: 'post',
        data: parameter
    })
}


/**
 * 删除OSS上文件
 * @param {key:course/20201214/5313078824656884887.jpg} parameter 
 */
 export function delAliYunFile(parameter) {
    return request({
        url: apis.DelAliYunFile,
        method: 'post',
        data: parameter
    })
}


/**
 * 获取被删除模块列表
 * @param {sectionId:8} parameter 
 */
 export function missingActionList(parameter) {
    return request({
        url: apis.MissingActionList,
        method: 'post',
        data: parameter
    })
}


/**
 * 获取被删除附件列表 课前课中课后
 * @param {sectionId:55,apxType:必须 1课前|2课中|3课后} parameter 
 */
 export function missingSectionApxList(parameter) {
    return request({
        url: apis.MissingSectionApxList,
        method: 'post',
        data: parameter
    })
}


/**
 * 新增附件 课中模块附件[上传OSS|链接]
 * @param {"sectionId":54,"appendixType":3,"purpose":"网上的资源","apxTag":"拓展资源","linkUrl":"http://www.smd.com/c/1.jpg"} parameter 
 * 附件信息 必须，appendixType [1课前|2课中|3课后]，purpose 教学用途，链接，两个结构二选一
 */
 export function addSectionApx(parameter) {
    return request({
        url: apis.AddSectionApx,
        method: 'post',
        data: parameter
    })
}

/**
 * 新增附件 课中模块附件[上传OSS|链接]
 * @param {"actionId":214,"purpose":"网上找的","linkUrl":"http://www.smd.com/c/1.jpg"} parameter 
 */
 export function addLessonApx(parameter) {
    return request({
        url: apis.AddLessonApx,
        method: 'post',
        data: parameter
    })
}


/**
 * 课中模块排序
 * @param {
 * model:[{"aid":187,"sort":1},{"aid":188,"sort":2},{"aid":189,"sort":3},{"aid":190,"sort":4},{"aid":191,"sort":5}]
 * } parameter 
 */
export function actionSort(parameter) {
    return request({
        url: apis.ActionSort,
        method: 'post',
        data: parameter
    })
}


/**
 * 更新模块信息[名称|目标|背景色]
 * @param {
 * actionId:'55',
 * newValue:'',
 * filed:修改字段 name 默认|target|bgColor
 * } parameter 
 */
export function updataActionInfo(parameter) {
    return request({
        url: apis.UpdataActionInfo,
        method: 'post',
        data: parameter
    })
}


/**
 * 删除附件 课中模块附件
 * @param {apxId:'55',type:模式 必须 [0删除 1恢复]} parameter 
 */
 export function delReLessonApx(parameter) {
    return request({
        url: apis.DelReLessonApx,
        method: 'post',
        data: parameter
    })
}


/**
 * 删除恢复附件 课前课中课后
 * @param {apxId:'55',type:模式 必须 [0删除 1恢复]} parameter 
 */
 export function delReSectionApx(parameter) {
    return request({
        url: apis.DelReSectionApx,
        method: 'post',
        data: parameter
    })
}

/**
 * 在课中删除活动
 * @param {actionId:'55'} parameter 
 */
 export function delMyAction(parameter) {
    return request({
        url: apis.DelMyAction,
        method: 'post',
        data: parameter
    })
}

/**
 * 依据单元ID获取单元解读
 * @param {unitId:'55'} parameter 
 */
 export function getUnitExplainById(parameter) {
    return request({
        url: apis.UnitExplain,
        method: 'post',
        data: parameter
    })
}

/**
 * 获取单元附件
 * @param {model:{"unitId":81,"type":1,"del":1}} parameter 
 * 必须 unitId 单元id，type 类型 [1 单元导学|2 单元评价]，del 标记 [1可用|0已删除]
 */
 export function getUnitApxList(parameter) {
    return request({
        url: apis.GetUnitApxList,
        method: 'post',
        data: parameter
    })
}

/**
 * 删除恢复附件 单元
 * @param {apxId:63,type:模式 必须 [0删除 1恢复]} parameter 
 */
 export function delReUnitApx(parameter) {
    return request({
        url: apis.DelReUnitApx,
        method: 'post',
        data: parameter
    })
}

/**
 * 新增附件 单元附件[上传OSS|链接]
 * @param {model:} parameter 
 * {"unitId":82,"appendixType":1,"purpose":"思维导图","apxTag":"课后练习","OldFileName":"pic.jpg","SourceUrl":"course/20201214/4905685887391632586.png","FileType":"image/jpeg","FileSize":9101}
 */
 export function addUnitApx(parameter) {
    return request({
        url: apis.AddUnitApx,
        method: 'post',
        data: parameter
    })
}


/**
 * 单元解读更新
 * @param {*
 * unitid 单元id号
 * newValue  修改后的数据
 * filed   要进行修改的字段
 * filed参数值：
 * unitbackground  单元背景
 * unittarget   单元目标
 * unitmindmap   单元结构
 * lessonplanning  课时划分
 * teachingsuggestion 教学建议
 * unitevaluation  单元设计框架
 * } parameter 
 */
export function updateUnit(parameter) {
    return request({
        url: apis.UpdateUnit,
        method: 'post',
        data: parameter
    })
}

/**
 * 物理删除附件
 * @param {apxId:4,type:附件所在区域 [1 section 默认|2 action]} parameter 
 */
 export function removeApx(parameter) {
    return request({
        url: apis.RemoveApx,
        method: 'post',
        data: parameter
    })
}

/**
 * 依据ID获取课时信息
 * @param {sectionId:'55'} parameter 
 */
 export function getSectionById(parameter) {
    return request({
        url: apis.SectionInfo,
        method: 'post',
        data: parameter
    })
}

/**
 * 更新课时目标
 * @param {sectionId:'55', target:'新得目标'} parameter 
 */
 export function updateMySection(parameter) {
    return request({
        url: apis.UpdateMySection,
        method: 'post',
        data: parameter
    })
}


/**
 * 在课中创建新活动
 * @param {sectionId:'55'} parameter 
 */
 export function createMyAction(parameter) {
    return request({
        url: apis.CreateMyAction,
        method: 'post',
        data: parameter
    })
}

/**
 * 设置单元被选中，用于下次进入回显
 * @param {unitId:0,course:'语文'} parameter 
 */
 export function setUnitSelect(parameter) {
    return request({
        url: apis.SetUnitSelect,
        method: 'post',
        data: parameter
    })
}